<template>
   <div>
      <v-dialog
              v-model="dialog"
              persistent
              scrollable
              width="400"
      >
         <v-card>

            <v-card-title>
               Services
               <v-spacer/>
               <v-icon @click="close">mdi-close</v-icon>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-4">

               <v-list>
                  <div v-for="(item, index) in services" :key="index">
                     <v-list-item>
                        <v-list-item-content>
                           <v-list-item-title >{{item.name}}</v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>

                     <v-divider v-if="index < services.length - 1" ></v-divider>

                  </div>
               </v-list>

            </v-card-text>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            services: [],
        }
    },
    methods: {
        open(services) {
            this.dialog = true
            this.services = services
        },
        close() {
            this.dialog = false
        },
    }
}
</script>

