<template>
  <div>
    <v-dialog
            v-model="dialog"
            persistent
            scrollable
            width="400"
    >

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-select v-model="fee_type_id"
                    :items="types"
                    :loading="isLoading"
                    hide-details
                    item-text="name"
                    item-value="id"
                    label="Type"
          ></v-select>

          <v-radio-group v-model="is_active" hide-details label="Statut">
            <v-radio
                    v-for="(item,i) in statuses"
                    :key="i"
                    :label="item.name"
                    :value="item.value"
            ></v-radio>
          </v-radio-group>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn block
                 color="primary"
                 depressed type="submit"
                 @click="handleFilter">
            <v-icon left>mdi-magnify</v-icon>
            Rechercher
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            isLoading: false,
            dialog: false,
            fee_type_id: null,
            assignment_type: null,
            is_active: 'all',
            types: []
        }
    },
    methods: {
        open() {
            this.fee_type_id = null
            this.is_active = 'all'
            this.dialog = true
            if (!this.types.length) {
                this.getFeeTypes()
            }
        },
        close() {
            this.dialog = false
        },
        handleFilter() {
            let data = {
                fee_type_id: this.fee_type_id,
                is_active: this.is_active,
            }
            this.$emit('filter', data)
            this.close()
        },
        getFeeTypes() {
            this.isLoading = true;
            HTTP.get("/v1/fees/types").then((res) => {
                this.types = res.data.data;
                this.isLoading = false;
            }).catch((e) => {
                this.isLoading = false;
                console.log(e);
            });
        },
    },
    computed: {
        statuses() {
            return [
                {name: 'Tous', value: 'all'},
                {name: 'Active', value: true},
                {name: 'Inactive', value: false},
            ]
        },
    },
}
</script>

